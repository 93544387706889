import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  Button,
  Box,
  Paper,
  Chip,
  Divider,
  Tooltip,
} from "@mui/material";

import GroupIcon from "@mui/icons-material/Group";
import CardGiftcardOutlinedIcon from "@mui/icons-material/CardGiftcardOutlined";
import PrecisionManufacturingOutlinedIcon from "@mui/icons-material/PrecisionManufacturingOutlined";
import RotateLeftOutlinedIcon from "@mui/icons-material/RotateLeftOutlined";
import ArticleIcon from "@mui/icons-material/Article";
import HelpIcon from "@mui/icons-material/Help";
import { BASE_URL } from "./../../global";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import PaymentReport from "../dashboard/paymentReport";
import StdInOut from "../dashboard/stdInOut";
import Dashboard_background from "../image/client.jpg";
import { dashboardGuide } from "./guide";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import ErrorModal from "../../utils/components/errorModal";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

import TodaysStdInOut from "./todaysStdInOut";

const CustomCard = ({ icon, iconColor, title, count, link }) => {
  const IconComponent = icon; // Dynamically set the icon component

  return (
    <Link to={link} style={{ textDecoration: "none" }}>
      <Card
        className="cardContent"
        sx={{
          m: 2,
          p: 1,
          display: "flex",
          border: 1,
          borderColor: "#283593",
        }}
        style={{ height: "125px" }}
        onClick={()=>{
          if(title == "Master Renewals"){
            sessionStorage.setItem("searched", "true");
          }
        }}
      >
        <IconComponent
          sx={{ color: iconColor || "#283593" }}
          style={{ fontSize: "100px" }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CardContent sx={{ flex: "1 0 auto" }}>
            <Typography>{title}</Typography>
            <Typography
              component="div"
              variant="h4"
              sx={{
                fontWeight: "bold",
                color: iconColor,
              }}
            >
              {count}
            </Typography>
          </CardContent>
        </Box>
      </Card>
    </Link>
  );
};

export default function Dashboard(props) {
  const dashboardGuides = dashboardGuide();
  const [finalData, setFinalData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [certificate, setCertificate] = useState([]);
  const [certReadings, setCertReadings] = React.useState([]);
  const [clientCount, setClientCount] = React.useState("");
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [certificateCount, setCertificateCount] = React.useState("");
  const [userType, setUserType] = React.useState(localStorage.getItem("type"));
  const [userId, setUserId] = React.useState(localStorage.getItem("id"));
  const [counts, setCounts] = useState({
    todaysClientsCount: 0,
    todaysSrfsCount: 0,
    todaysCertificatesCount: 0,
    todaysRenewalsCount: 0,
    overAllActiveIncCount: 0,
    overAllRenewalCount: 0,
    overAllClientDueCount: 0,
    todayClientDueCount: 0,
    masterRenewalCount: 0,
  });
  const [errormodalIsOpen, setErrorModalIsOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const _clientId = localStorage.getItem("clientId");
  const disciplineID = localStorage.getItem("disciplineID");
  const [datashheetCalibratedPending, setDatashheetCalibratedPending] = useState(0);
  const [certificateApprovedCount, setCertificateApprovedCount] = useState(0);
  const openErrorModal = (errorMessage, stackTrace, fileName) => {
    setErrorMessage({ errorMessage, stackTrace, fileName });
    setErrorModalIsOpen(true);
  };
  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  // api calls
  function fetchCertificateReadings() {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `certificateStaticReadings`)
      .then(async (res) => {
        setCertReadings(res.data);
      })
      .catch((err) => {
        console.log("cert data fetching error: ", err);
      });
  }

  const getClientCount = (event) => {
    let url = BASE_URL;
    axiosWithToken
      .get(
        url +
          `clients/count?_where=(status,ne,-1)${
            userType == 3 ? `~and(id,eq,${_clientId})` : ""
          }`
      )
      .then((res) => {
        setClientCount(res.data[0].no_of_rows);
      })
      .catch((err) => {
        if (err.message !== "request_aborted") {
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(err.message, "getClientCount", "Dashboard")
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
        }
      });
  };

  const getCertificateCount = (event) => {
    let url = BASE_URL;
    axiosWithToken
      .get(
        url +
          `certificates/count${
            userType == 3 ? `?_where=(clientId,eq,${_clientId})` : ""
          }`
      )
      .then((res) => {
        setCertificateCount(res.data[0].no_of_rows);
      })
      .catch((err) => {
        if (err.message !== "request_aborted") {
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(err.message, "getCertificateCount", "Dashboard")
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
        }
      });
  };

  const getCertificateNotApproved = () => {
    let query = {
      query: `SELECT count(*) as no_of_rows FROM certificatesView where status != -1 and calibratedby is NOT NULL and approvedby is NULL and discID IN (${disciplineID})`,
    };
    axiosWithToken
      .post(BASE_URL + `dynamic`, query)
      .then((res) => {
        let resCount = res.data[0].no_of_rows;
        // console.log(res.data[0].no_of_rows)
        setCertificateApprovedCount(resCount);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDatasheetCalibrated = () => {
    let query = {
      query: `select count(*) as no_of_rows from datasheets where assignedUser= ${userId} and calibratedby is null`,
    };
    axiosWithToken
      .post(BASE_URL + `dynamic`, query)
      .then((res) => {
        let resCount = res.data[0].no_of_rows;
        // console.log(res.data[0].no_of_rows)
        setDatashheetCalibratedPending(resCount);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getClientCount();
    getCertificateCount();
    fetchCertificateReadings();
    getCertificateNotApproved();
    getDatasheetCalibrated();
  }, []);

  /////////////////////////////DashboardData////////////////////////

  const fetchCount = async (table, dateColumn, condition) => {
    try {
      const url = `${BASE_URL}dynamic`;
      let query;
      if (table == "standards") {
        query = `SELECT * FROM ${table} WHERE DATE(${dateColumn}) ${condition} AND status = 1`;
      }
      else{
        query = `SELECT * FROM ${table} WHERE DATE(${dateColumn}) ${condition}`;
      }
      if (userType == 3) {
        query += ` AND clientId = ${_clientId}`;
      }
      const response = await axiosWithToken.post(url, { query });
      return response.data.length;
    } catch (error) {
      console.error(error);
      return 0;
    }
  };

  const fetchDataCounts = async () => {
    const masterRenewalCount = await fetchCount(
      "standards",
      "validUpto",
      "<= CURDATE()"
    );
    const todaysClientsCount = await fetchCount(
      "clients",
      "lastModified",
      "= CURDATE()"
    );
    const todaysSrfsCount = await fetchCount(
      "srfs",
      "lastModified",
      "= CURDATE()"
    );
    const todaysCertificatesCount = await fetchCount(
      "certificates",
      "lastModified",
      "= CURDATE()"
    );
    const todaysRenewalsCount = await fetchCount(
      "certificates",
      "nextDueDate",
      "= CURDATE()"
    );
    const overAllActiveIncCount = await fetchCount(
      "standards",
      "validUpto",
      "<= CURDATE()"
    );
    const overAllRenewalCount = await fetchCount(
      "certificates",
      "nextDueDate",
      "<= CURDATE()"
    );
    const todayClientDueCount = await fetchCount(
      "certificates",
      "nextDueDate",
      "= CURDATE()"
    );

    setCounts((prevCounts) => ({
      ...prevCounts,
      todaysClientsCount,
      todaysSrfsCount,
      todaysCertificatesCount,
      todaysRenewalsCount,
      overAllActiveIncCount,
      overAllRenewalCount,
      todayClientDueCount,
      masterRenewalCount,
    }));
  };

  useEffect(() => {
    fetchDataCounts();
  }, []);

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        {certificateApprovedCount != 0 &&
          props.rolesInfo?.currentRole?.id == 2 && (
            <div
              className="shadow-sm p-1 rounded position-fixed"
              style={{ right: "1rem" }}
            >
              <Link to="/certificate/count">
                <button
                  type="button"
                  className="btn btn-warning btn-md"
                  onClick={() => sessionStorage.setItem("searched", "true")}
                >
                  <b>Approval Request ({certificateApprovedCount})</b>
                </button>
              </Link>
            </div>
          )}

        {datashheetCalibratedPending != 0 &&
          props.rolesInfo?.currentRole?.id == 1 && userType == 2 && (
            <div
              className="shadow-sm p-1 rounded position-fixed"
              style={{ right: "1rem" }}
            >
              <Link to="/datasheet/count">
                <button
                  type="button"
                  className="btn btn-warning btn-md"
                  onClick={() => sessionStorage.setItem("searched", "true")}
                >
                  <b>Job Request ({datashheetCalibratedPending})</b>
                </button>
              </Link>
            </div>
          )}

        {userType == 2 || userType == 3 || userType == 5 || userType == 6 ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              style={{
                height: "auto",
                maxHeight: "100%",
                maxWidth: "100%",
                width: "95%",
                objectFit: "contain",
              }}
              src={Dashboard_background}
              alt="dashboard_background"
            />
          </div>
        ) : (
          <>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Tooltip title="User Guide" placement="top-start">
                <Button
                  onClick={() => {
                    dashboardGuides.drive();
                  }}
                >
                  <HelpIcon />
                </Button>
              </Tooltip>
            </div>
            <div component={Paper} sx={{ mt: 2 }}>
              <Divider sx={{ mt: 5 }}>
                <Chip
                  label="OVERALL"
                  variant="outlined"
                  sx={{ fontSize: "16px" }}
                  style={{ fontWeight: "bold" }}
                />
              </Divider>
              <Grid container spacing={3}>
                {userType != 3 && (
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <CustomCard
                      icon={GroupIcon}
                      iconColor="#283593"
                      title="Clients"
                      count={clientCount}
                      link="/master/client"
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <CustomCard
                    icon={CardGiftcardOutlinedIcon}
                    iconColor="#283593"
                    title="Certificates"
                    count={certificateCount}
                    link="/certificate"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <CustomCard
                    icon={PrecisionManufacturingOutlinedIcon}
                    iconColor="#283593"
                    title="UUT Renewals"
                    count={counts.overAllRenewalCount}
                    link="/scheduler"
                  />
                </Grid>
                {userType != 3 && (
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <CustomCard
                      icon={RotateLeftOutlinedIcon}
                      iconColor="#283593"
                      title="Master Renewals"
                      count={counts.masterRenewalCount}
                      link="/master/masterEQP/count"
                    />
                  </Grid>
                )}
                {userType == 3 && (
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <CustomCard
                      icon={RotateLeftOutlinedIcon}
                      iconColor="#283593"
                      title="Dues"
                      count={counts.overAllClientDueCount}
                      link="/scheduler"
                    />
                  </Grid>
                )}
              </Grid>
            </div>
            <div component={Paper} sx={{ mt: 5 }}>
              <Divider sx={{ mt: 5 }}>
                <Chip
                  label="TODAY'S"
                  variant="outlined"
                  sx={{ fontSize: "1rem" }}
                  style={{ fontWeight: "bold" }}
                />
              </Divider>
              <Grid container spacing={3}>
                {userType != 3 && (
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <CustomCard
                      icon={GroupIcon}
                      iconColor="#ff5722"
                      title="New clients"
                      count={counts.todaysClientsCount}
                      link="/master/client"
                    />
                  </Grid>
                )}
                {userType != 3 && (
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <CustomCard
                      icon={ArticleIcon}
                      iconColor="#ff5722"
                      title="New UUT Inward"
                      count={counts.todaysSrfsCount}
                      link="/srf"
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <CustomCard
                    icon={CardGiftcardOutlinedIcon}
                    iconColor="#ff5722"
                    title="Certificates"
                    count={counts.todaysCertificatesCount}
                    link="/certificate"
                  />
                </Grid>
                {userType != 3 && (
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <CustomCard
                      icon={RotateLeftOutlinedIcon}
                      iconColor="#ff5722"
                      title="UUT Renewals"
                      count={counts.todaysRenewalsCount}
                      link="/scheduler"
                    />
                  </Grid>
                )}
                {userType == 3 && (
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <CustomCard
                      icon={RotateLeftOutlinedIcon}
                      iconColor="#ff5722"
                      title="Dues"
                      count={counts.todayClientDueCount}
                      link="/scheduler"
                    />
                  </Grid>
                )}
              </Grid>
            </div>

            <div component={Paper} sx={{ mt: 5 }}>
              <Divider sx={{ mt: 5 }}>
                <Chip
                  label="ANALYTICS"
                  variant="outlined"
                  sx={{ fontSize: "16px" }}
                  style={{ fontWeight: "bold" }}
                />
              </Divider>
              <Tabs
                defaultActiveKey="profile"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="profile" title="Commercial Today's Report">
                  <PaymentReport />
                </Tab>
                <Tab eventKey="master" title="Overall Master Out-in">
                  <StdInOut />
                </Tab>
                <Tab eventKey="todaysMaster" title="Today Master Out-in">
                  <TodaysStdInOut />
                </Tab>
              </Tabs>
            </div>
          </>
        )}

        {errormodalIsOpen && (
          <ErrorModal
            errormodalIsOpen={errormodalIsOpen}
            closeErrorModal={closeErrorModal}
            errorMessage={errorMessage}
          />
        )}
      </div>
    </>
  );
}
