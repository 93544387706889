import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import axiosWithToken from "../../utils/components/axiosTokenConfig";

const tableCellStyle = {
    // border: "1px solid black",
    borderRight:"none",
    padding: "8px",
  };

  const tableHeadStyle = {
    backgroundColor: "#1976d2",
    color: "white",
    fontWeight: "bold",
    ...tableCellStyle,
  };

const DispatchStatus = () => {
    const [instruments, setInstruments] = React.useState([])
    const [dispatch, setdispatch] = React.useState({})
    const params = useParams();


  function getInstruments() {
    let payload = {
      query: `SELECT si.id AS srfInstrumentId, si.srfsId, si.instrumentId, CASE WHEN FIND_IN_SET(si.id, REPLACE(di.instrumentIds, ' ', '')) > 0 THEN 'Yes' ELSE 'No' END AS dispatchStatus, REPLACE(REPLACE(si.ranges, '#', ''), '|', ' to ') AS ranges, REPLACE(REPLACE(si.lc, '#', ''), '|', ' to ') AS lc, REPLACE(REPLACE(si.accuracy, '#', ''), '|', ' to ') AS accuracy, si.serialNumber, si.DUCID, it.instrumentName FROM srfInstruments AS si LEFT JOIN dispatch AS di ON si.srfsId = di.srfsId AND FIND_IN_SET(si.id, REPLACE(di.instrumentIds, ' ', '')) > 0 AND di.status = 1 JOIN instruments AS it ON si.instrumentId = it.id WHERE si.srfsId = ${params.id}`,
    };
    axiosWithToken
      .post(BASE_URL + `dynamic`, payload)
      .then((res) => {
        setInstruments(res.data);
      })
      .catch((err) => {
        console.log("clients data fetching error: ", err);
      });
  }

  function getDispatchData() {
    let payload = {
      query: `SELECT COUNT(*) AS \"totalInstruments\", SUM(dispatchStatus = 'Yes') AS \"dispatchedInstruments\", SUM(dispatchStatus = 'No') AS \"pendingInstruments\", MAX(srfs.serviceReqNumber) AS \"serviceReqNumber\" FROM (SELECT si.id AS srfInstrumentId, si.srfsId, si.instrumentId, CASE WHEN FIND_IN_SET(si.id, REPLACE(di.instrumentIds, ' ', '')) > 0 THEN 'Yes' ELSE 'No' END AS dispatchStatus, REPLACE(REPLACE(si.ranges, '#', ''), '|', ' to ') AS ranges, REPLACE(REPLACE(si.lc, '#', ''), '|', ' to ') AS lc, REPLACE(REPLACE(si.accuracy, '#', ''), '|', ' to ') AS accuracy, si.serialNumber, si.DUCID, it.instrumentName FROM srfInstruments AS si LEFT JOIN dispatch AS di ON si.srfsId = di.srfsId AND FIND_IN_SET(si.id, REPLACE(di.instrumentIds, ' ', '')) > 0 AND di.status = 1 JOIN instruments AS it ON si.instrumentId = it.id WHERE si.srfsId = ${params.id}) AS subquery LEFT JOIN srfs ON subquery.srfsId = srfs.id`,
    };
    axiosWithToken
      .post(BASE_URL + `dynamic`, payload)
      .then((res) => {
        let data = res.data[0];
        setdispatch(data);
      })
      .catch((err) => {
        console.log("clients data fetching error: ", err);
      });
  }

useEffect(()=>{
    getInstruments()
    getDispatchData()
},[])

  return (
    <div>

<div
    //    ref={printComponentRef}
        style={{
          width: "95%",
          border: "1px solid black",
          textAlign: "center",
          margin: "10px auto",
          padding: "10px",
        }}
      >
        <div style={{ width: "100%" }} id="header-section"></div>
        <Grid container spacing={2}>
          <Grid md={12} className="font-link" align="center">
            <h5 style={{ color: "#000066", margin: "30px 0px" }}>
              <b> DISPATCH STATUS </b>
            </h5>
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          <Table sx={{  width:"92%", margin:"auto" }} size="small" aria-label="a dense table">
            <TableBody>
              <TableRow>
                <TableCell
                  align="left"
                  style={{
                    backgroundColor: "#D9D9D9",
                    fontWeight: "600",
                    width: "22%",
                  }}
                >
                  Service Request Number
                </TableCell>
                <TableCell align="left" style={{ width: "28%" }}>
                  {dispatch?.serviceReqNumber}
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    backgroundColor: "#D9D9D9",
                    fontWeight: "600",
                    width: "22%",
                  }}
                >
                 Total Instruments
                </TableCell>
                <TableCell align="left" style={{ width: "28%" }}>
                {dispatch?.totalInstruments}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="left"
                  style={{
                    backgroundColor: "#D9D9D9",
                    fontWeight: "600",
                    width: "22%",
                  }}
                >
                 Dispatch Instrument
                </TableCell>
                <TableCell align="left" style={{ width: "28%" }}>
                {dispatch?.dispatchedInstruments}
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    backgroundColor: "#D9D9D9",
                    fontWeight: "600",
                    width: "22%",
                  }}
                >
                 Pending Instrument 
                </TableCell>
                <TableCell align="left" style={{ width: "28%" }}>
                {dispatch?.pendingInstruments}
                </TableCell>
              </TableRow>
      
            </TableBody>
          </Table>
        </TableContainer>

     


<Paper sx={{ padding: "40px" }}>
          <Table sx={{ minWidth: 650 }}>
    <TableHead>
      <TableRow>
        <TableCell style={tableHeadStyle}>Sr. No.</TableCell>
        <TableCell style={tableHeadStyle}>UUT Name</TableCell>
        <TableCell style={tableHeadStyle}>Range</TableCell>
        <TableCell style={tableHeadStyle}>LC</TableCell>
        <TableCell style={tableHeadStyle}>Accuracy</TableCell>
        <TableCell style={tableHeadStyle}>Dispatch Status</TableCell>
        <TableCell style={{ ...tableHeadStyle, borderRight: "1px solid black"  }}>DUCID</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {instruments?.map((row, index) => (
        <TableRow key={index}>
          <TableCell style={tableCellStyle}>{index + 1}</TableCell>
          <TableCell style={tableCellStyle}>{row?.instrumentName}</TableCell>
          <TableCell style={tableCellStyle}>{row?.ranges}</TableCell>
          <TableCell style={tableCellStyle}>{row?.lc}</TableCell>
          <TableCell style={tableCellStyle}>{row?.accuracy}</TableCell>
          <TableCell style={tableCellStyle}>{row?.dispatchStatus}</TableCell>
          <TableCell style={{ ...tableCellStyle, borderRight: "1px solid black" }}>{row?.DUCID}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
        </Paper>
        </div>
    </div>
  )
}

export default DispatchStatus