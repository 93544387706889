import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import Toolbar from "@mui/material/Toolbar";
import { Link, useLocation } from "react-router-dom";
import HelpIcon from "@mui/icons-material/Help";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import {
  TextField,
  Autocomplete,
  Modal,
  Grid,
  useMediaQuery,
  Table,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { instrumentListGuide } from "./guide";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";
import ErrorModal from "../../../utils/components/errorModal";
import ExcelDownload from "../../../utils/components/excelDownload";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");



const columns = [
  { id: "id", label: "Sr. No", minWidth: 10 },
  { id: "instrumentName", label: "UUT Name", minWidth: 30 },
  { id: "discipline", label: "Discipline", minWidth: 30 },
  {
    id: "calibrationProcedureNo",
    label: "Calibration Procedure No",
    minWidth: 30,
  },
  { id: "referenceStandards", label: "Referenece Standards", minWidth: 30 },
];

function createData(
  id,
  instrumentName,
  discipline,
  calibrationProcedureNo,
  referenceStandards
) {
  return {
    id,
    instrumentName,
    discipline,
    calibrationProcedureNo,
    referenceStandards,
  };
}

const notNeededColumn = [
  "id",

];

export default function InstrumentsList() {
  const instrumentListGuides = instrumentListGuide();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const urlPath = useLocation();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [instruments, setInstrumentList] = React.useState([]);
  const [searchBy, setSearchBy] = React.useState(sessionStorage.getItem("searchBy") || "UUT Name");
  const [searchKey, setSearchKey] = React.useState(sessionStorage.getItem("searchKey") || "");  
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [totalRows, setTotalRows] = React.useState(0);
  const [rowDaata, setRowData] = React.useState([]);
  const [filterModalOpen, setFilterModalOpen] = React.useState(false);
  const [errormodalIsOpen, setErrorModalIsOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [searched, setSearched] = React.useState(false);
  const [editAccess, setEditAccess] = React.useState(localStorage.getItem("editAccess")?.split(",").map(Number));
	  

  const openErrorModal = (errorMessage,stackTrace,fileName) => {
    setErrorMessage({ errorMessage, stackTrace, fileName });
    setErrorModalIsOpen(true);
  };
  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  const handleFilterOpen = () => setFilterModalOpen(true);
  const handleFilterClose = () => setFilterModalOpen(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const submitSiteDelete = (id) => {
    var jsonData = {
      status: -1,
    };
    let url = BASE_URL;
    axiosWithToken
      .patch(url + `instruments/${id}`, jsonData)
      .then((res) => {
        toast("Instrument was deleted!");
        setTimeout(refresh, 500);
      })
      .catch((err) => {
        if (err.message !== "request_aborted") {
          toast.error(<h6 onClick={() => openErrorModal(err.message, "submitSiteDelete" ,"Instrument List")}><u>Error: {err.message}</u></h6>);
        }
      });
  };

  var refresh = () => {
    window.location.reload(false);
    // <Link to="/master/master/standard">Home</Link>
  };
  const getQuery = (count) => {
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey != "") {
      let colName = {
        "UUT Name": "inst.instrumentName",
        "Calibration Procedure No.": "inst.calibrationProcedureNo",
        Discipline: "ds.name",
      }[searchBy];
      whereConditions = `where ${colName} like '%${searchKey}%'`;
    }

    if (to != null && from != null) {
      if (whereConditions === "") {
        whereConditions = " where ";
      } else {
        whereConditions += " and ";
      }

      whereConditions += ` lastModified between '${moment(from).format(
        "YYYY-MM-DD"
      )}' and '${moment(to).format("YYYY-MM-DD")}' `;
    }

    if (whereConditions == "") {
      whereConditions += " where inst.status = 1  ";
    } else {
      whereConditions += " and inst.status = 1  ";
    }

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

    let data = {
      query: `select ds.name,inst.id,inst.instrumentName,inst.calibrationProcedureNo,inst.referenceStandards from instruments as inst left join discipline as ds on inst.disciplineId = ds.id ${whereConditions} order by inst.id DESC ${pagination_settings}`,
    };

    if (count)
      data.query = `select count(*) as no_of_rows from instruments as inst left join discipline as ds on inst.disciplineId = ds.id ${whereConditions} order by inst.id DESC`;

    return data;
  };

  function getClientList() {
    
 let data = getQuery();
    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setInstrumentList([...res.data]);
      })
      .catch((err) => {
        if (err.message !== "request_aborted") {
          toast.error(<h6 onClick={() => openErrorModal(err.message, "getClientList" ,"Instrument List")}><u>Error: {err.message}</u></h6>);
        }
      });
  }

  // api calls
  function getTotalRows() {
    let data = getQuery(true);
    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setTotalRows(res.data[0]?.no_of_rows);
      })
      .catch((err) => {
        console.log("clients data fetching error: ", err);
        if (err.message !== "request_aborted") {
          toast.error(<h6 onClick={() => openErrorModal(err.message, "getTotalRows" ,"Instrument List")}><u>Error: {err.message}</u></h6>);
        }
      });
  }

  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  };
  const storedSearchBy = sessionStorage.getItem("searched");
  useEffect(() => {
    if(storedSearchBy){
      setSearched(true);
    }
  }, [storedSearchBy]);

  const search = () => {
    sessionStorage.setItem("searchBy", searchBy);
    sessionStorage.setItem("searchKey", searchKey);
    sessionStorage.setItem("searched", "true");
    sessionStorage.setItem("currentPath", urlPath.pathname); 
    setSearched(true);
    getClientList();
    getTotalRows();
  };
  const clearSearch = () => {
    sessionStorage.removeItem("searchBy");
    sessionStorage.removeItem("searchKey");
    sessionStorage.removeItem("searched", searched);
    sessionStorage.removeItem("currentPath");
    setSearchBy("UUT Name");
    setSearchKey("");
    setSearched(false); 
  };

  useEffect(() => {
      getTotalRows();
    getClientList();
  }, [rowsPerPage, page]);

  useEffect(() => {
    if (searched==false) {
      getTotalRows();
    getClientList();
  }
  if (searched) {
    setPage(0);
  }
  }, [searched]);

  const generateRowData = (instruments) => {
    const newRowData = instruments.map((instrument) => {
      return createData(
        instrument.id,
        instrument.instrumentName,
        instrument.name,
        instrument.calibrationProcedureNo,
        instrument.referenceStandards
      );
    });

    setRowData(newRowData);
  };

  const copyInstrument = async (id) => {
    let instrument = await axiosWithToken
      .get(BASE_URL + `instruments/${id}`)
      .then((res) => res.data[0] || []);
    delete instrument.id;
    delete instrument.lastModified;
    delete instrument.revisionDate;
    let cmcs = await axiosWithToken
      .get(BASE_URL + `cmc?_where=(instrumentId,eq,${id})`)
      .then((res) => res.data || []);
    axiosWithToken.post(BASE_URL + "instruments", instrument).then((res) => {
      cmcs.map((cmc) => {
        delete cmc.id;
        delete cmc.lastModified;
        cmc.instrumentId = res.data.insertId;
      });
      axiosWithToken
        .post(BASE_URL + "cmc/bulk", cmcs)
        .then((res2) => res2)
        .catch((error) => console.log(error));
    });
    setTimeout(refresh, 500);
    console.log("data : ", instrument);
  };

  useEffect(() => {
    generateRowData(instruments);
  }, [instruments]);

  const filterSection = () => (
    <Grid
      container
      spacing={2}
      // justifyContent="flex-end"
      alignItems="center"
      style={{ padding: "10px 5px" }}
    >
      <Grid item xs={12} sm={12} md={2} lg={2}>
        <Autocomplete
          sx={{
            m: 0,
            minWidth: 180,
          }}
          size="small"
          value={searchBy}
          id="Instrument_list_searchby"
          options={[
            { key: "instrumentName", label: "UUT Name" },
            {
              key: "calibrationProcedureNo",
              label: "Calibration Procedure No.",
            },
            { key: "discipline", label: "Discipline" },
          ]}
          renderInput={(params) => <TextField {...params} label="Search By" />}
          onInputChange={(event, newInputValue) => {
            setSearchBy(newInputValue);
            setSearched(false);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={2} lg={2}>
        <TextField
          style={{ width: "100%" }}
          id="Instrument_list_searchbytext"
          label={"enter " + searchBy}
          size="small"
          value={searchKey}
          inputProps={{
            autoComplete: 'off',
          }}
          variant="outlined"
          onChange={(e) => {
            setSearchKey(e.target.value)
            setSearched(false);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={1} lg={1}>
        <Button
          id="Instrument_list_searchbtn"
          variant="contained"
          size="small"
          style={{height:"40px"}}
          onClick={() => {
            searched ? clearSearch() : search();
            handleFilterClose();
          }}
        >
          {searched ? <RestartAltIcon /> : <SearchIcon />}
        </Button>
     
      </Grid>
      <Grid item xs={12} sm={12} md={2} lg={2}>
      <ExcelDownload
          finalData={rowDaata}
          notNeededColumn={notNeededColumn}
        />
      </Grid>
    </Grid>
  );
  const filterModal = () => {
    return (
      <Modal open={filterModalOpen} size="md">
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "calc(100% - 30%)",
            backgroundColor: "white",
            // border: "2px solid #000",
            boxShadow: 24,
            padding: "10px",
          }}
        >
          <Button
            variant="contained"
            size="small"
            color="error"
            style={{ float: "right" }}
            onClick={handleFilterClose}
          >
            X
          </Button>
          {filterSection()}
        </Box>
      </Modal>
    );
  };

  return (
    <Box sx={{ margin: "2px 5px", marginTop: "-0.8rem" }}>
      {filterModal()}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Toolbar style={{ padding: "0px" }}>
          <Button
            id="Instrument_list_createbtn"
            variant="contained"
            size="small"
            component={Link}
            to="/master/instrument/createInstrument"
          >
            <b>Create Instrument</b>
          </Button>
        </Toolbar>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            style={{ display: isMobile ? "block" : "none" }}
            onClick={handleFilterOpen}
          >
            <FilterAltIcon />
          </Button>
          <Tooltip title="User Guide" placement="top-start">
            <Button
              onClick={() => {
                instrumentListGuides.drive();
              }}
            >
              <HelpIcon />
            </Button>
          </Tooltip>
        </div>
      </div>
      {!isMobile && filterSection()}
      <TableContainer sx={{ maxHeight: isMobile ? "75vh" : "65vh" }}>
        <Table
          stickyHeader
          aria-label="sticky table"
          size="small"
          id="Instrument_list_table"
          sx={{ borderCollapse: "collapse" }}
        >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <b>{column.label}</b>
                </TableCell>
              ))}
              <TableCell>
                <b>Actions</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowDaata?.length > 0 ? (
              rowDaata.map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map((column) => {
                    let value = row[column.id];
                    if (column.id == "id") {
                      value = page * rowsPerPage + (index + 1);
                    }
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                  <TableCell>
                    <ButtonGroup size="small" aria-label="small button group">
                      <Tooltip title="Edit Instrument" placement="top-start">
                        <Button
                          id="Instrument_list_edit"
                          component={Link}
                          to={`/master/instrument/editInstrument/${row.id}`}
                        >
                          <PreviewIcon />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Copy Instrument" placement="top-start">
                        <Button
                          id="Instrument_list_copy"
                          disabled={!(editAccess?.includes(5) || editAccess?.includes(0))}
                          onClick={() => {
                            if (
                              window.confirm(
                                "Do want to copy and create new Instrument using selected instrument?"
                              )
                            ) {
                              copyInstrument(row.id);
                            }
                          }}
                        >
                          <ContentCopyIcon />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Delete Instrument" placement="top-start">
                        <Button
                          id="Instrument_list_delete"
                          disabled={!(editAccess?.includes(2) || editAccess?.includes(0))}
                          onClick={(e) => {
                            if (
                              window.confirm(
                                "Really want to delete Instrument?"
                              )
                            ) {
                              submitSiteDelete(row.id);
                            }
                          }}
                        >
                          <DeleteIcon   style={{ color:!(editAccess?.includes(2) || editAccess?.includes(0)) ? 'lightgray' : '#dc3545' }} />
                        </Button>
                      </Tooltip>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length + 1} align="center">
               <h6><b>Data Not Found</b></h6>
              </TableCell>
            </TableRow>
          )} 
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100, { label: "All", value: -1 }]}
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        component="div"
        colSpan={3}
        SelectProps={{
          inputProps: {
            "aria-label": "rows per page",
          },
          native: true,
        }}
        ActionsComponent={TablePaginationActions}
      />
      <ToastContainer />

      {errormodalIsOpen && <ErrorModal errormodalIsOpen={errormodalIsOpen} closeErrorModal={closeErrorModal} errorMessage={errorMessage} />}
    </Box>
  );
}
