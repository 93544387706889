import { driver } from "driver.js";

export const invoicenListGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Let's get started...",
          description:
            "This tour will guide you through all sections and components in this Invoice list.",
        },
      },
      {
        element: "#invoicelist_createbtn",
        popover: {
          title: "Create New invoice",
          description: "Click this button to create a new Invoice.",
        },
      },
      {
        element: "#invoicelist_datefrom",
        popover: {
          title: "Select Date",
          description: "Select the start date.",
        },
      },
      {
        element: "#invoicelist_dateto",
        popover: {
          title: "Select Date",
          description: "Select the end date.",
        },
      },
      {
        element: "#invoicelist_searchby",
        popover: {
          title: "Search Field",
          description: "Use this dropdown to search.",
        },
      },
      {
        element: "#invoicelist_searchbytext",
        popover: {
          title: "Enter / Update Your Value",
          description:
            "When you select the previous dropdown value, enter/update your value for the search.",
        },
      },
      {
        element: "#invoicelist_searchbtn",
        popover: {
          title: "Search",
          description:
            "When you select the previous dropdown or enter/update a value, click this button to get the result.",
        },
      },
      {
        element: "#stdMastersEQPList_convertexcel",
        popover: {
          title: "Print All invoices",
          description: "Below the list, you can get an Excel file.",
        },
      },
      {
        element: "#invoicelist_table",
        popover: {
          title: "Invoice Table",
          description:
            "Invoice table where you can view,  delete and manage invoices.",
        },
      },
      {
        element: "#invoicelist_edit",
        popover: {
          title: "Edit invoice",
          description: "Click this button to edit the invoice.",
        },
      },
      {
        element: "#invoicelist_download",
        popover: {
          title: "Download invoice",
          description: "Download invoice",
        },
      },
      {
        element: "#invoicelist_delete",
        popover: {
          title: "Delete invoice",
          description: "Click this button to delete the invoice.",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can manage invoices.",
        },
      },
    ],
  });
};

export const viewInvoiceGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Lets get started...",
          description:
            "This tour will guide you through all sections and components in this View Invoice.",
        },
      },
      {
        element: "#invoice_view_Partially",
        popover: {
          title: "Select Partially Invoice",
          description: "Select Partially Invoice",
        },
      },
      {
        element: "#invoice_view_letterhead",
        popover: {
          title: "Select Letterhead",
          description: "Select letterhead with or without",
        },
      },
      {
        element: "#invoice_view_printTitle",
        popover: {
          title: "Select Print Title",
          description: "Select print title where you can change title.",
        },
      },
      {
        element: "#invoice_view_Download",
        popover: {
          title: "Download Invoice",
          description:
            "Clicking this button allows you to download the invoice.",
        },
      },
      {
        element: "#invoice_view__print",
        popover: {
          title: "Invoice Print",
          description: "",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can download the invoice",
        },
      },
    ],
  });
};

export const createInvoiceGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Let's get started...",
          description:
            "This tour will guide you through all sections and components in creating / editing a Invoice.",
        },
      },
      {
        element: "#invoice_create_company",
        popover: {
          title: "Select Company",
          description: "Select the company using the dropdown.",
        },
      },
      {
        element: "#invoice_create_address",
        popover: {
          title: "Select Address",
          description:
            "When the company is selected, the address automatically fetches, and if more than one address, then you have to select the address.",
        },
      },
      {
        element: "#invoice_create_srfno",
        popover: {
          title: "Inward Number",
          description: "Select Inward Number.",
        },
      },
      {
        element: "#invoice_create_custChallanNo",
        popover: {
          title: "Enquiry Challan No",
          description: "Enter / Update Challan No.",
        },
      },
      {
        element: "#invoice_create_customerchallanDate",
        popover: {
          title: "Select Challan Date",
          description: "You have to select the challan Date.",
        },
      },
      {
        element: "#invoice_create_ourChallanNo",
        popover: {
          title: "Enquiry Challan No",
          description: "Enter / Update Challan No.",
        },
      },
      {
        element: "#invoice_create_ourchallanDate",
        popover: {
          title: "Select Challan Date",
          description: "You have to select the challan Date.",
        },
      },
      {
        element: "#invoice_create_referenceNumber",
        popover: {
          title: "Enter / Update Reference Number",
          description: "Optional Field.",
        },
      },
      {
        element: "#invoice_create_invoicenumber",
        popover: {
          title: "Invoice Number",
          description: "This Invoice number is automatically generated.",
        },
      },
      {
        element: "#invoice_create_invoiceDate",
        popover: {
          title: "Select Invoice Date",
          description: "You have to select the Invoice Date.",
        },
      },
      {
        element: "#invoice_create_subject",
        popover: {
          title: "Subject",
          description: "Optional field.",
        },
      },

      {
        element: "#invoice_create_termsConditions",
        popover: {
          title: "Update Terms Conditions",
          description: "Fetch Automatically from setting.",
        },
      },
      {
        element: "#invoice_create_gstType",
        popover: {
          title: "Select GST Type",
          description: "Select GST Type using dropdown.",
        },
      },
      {
        element: "#invoice_create_gstPercentage",
        popover: {
          title: "Select GST Percentage",
          description: "Select GST Percentage using dropdown.",
        },
      },
      {
        element: "#invoice_create_poNumber",
        popover: {
          title: "Select PO Number",
          description:
            "Select PO Number When Invoice Create (option disabled in edit invoice).",
        },
      },
      {
        element: "#invoice_create_poDate",
        popover: {
          title: "Select PO Date",
          description:
            "Select PO Date When Invoice Create (option disabled in edit invoice).",
        },
      },
      {
        element: "#invoice_create_equipmentDelivery",
        popover: {
          title: "Enter Equipment Delivery",
          description: "Optional field.",
        },
      },
      {
        element: "#invoice_create_excelUpload_uploadexcel",
        popover: {
          title: "Upload Excel",
          description:
            "Where you can upload an Excel file then fetch products and show below table.",
        },
      },
      {
        element: "#quotation_create_ProductDetails_addnewproduct",
        popover: {
          title: "Add New Row below Table",
          description: "Click this button to add a new row for the Invoice.",
        },
      },
      {
        element: "#quotation_create_ProductDetails_equipmentId",
        popover: {
          title: "Select / Update Equipment Name",
          description: "Select / Update Equipment Name.",
        },
      },
      {
        element: "#quotation_create_ProductDetails_description",
        popover: {
          title: "Enter / Update Description",
          description:
            "When Equipment is selected, it automatically fetches the description. If you want to change, you can also make changes.",
        },
      },
      {
        element: "#quotation_create_ProductDetails_hsnCode",
        popover: {
          title: "Select HSN / SAC Code",
          description:
            "When Equipment is selected, it automatically fetches the HSN / SAC code. If you want to change, you can also make changes.",
        },
      },
      {
        element: "#quotation_create_ProductDetails_service",
        popover: {
          title: "Select Service",
          description:
            "When Equipment is selected, it automatically fetches the service. If you want to change, you can also make changes.",
        },
      },
      {
        element: "#quotation_create_ProductDetails_rate",
        popover: {
          title: "Rate",
          description:
            "When Equipment is selected, it automatically fetches the rate. If you want to change, you can also make changes.",
        },
      },
      {
        element: "#quotation_create_ProductDetails_quantity",
        popover: {
          title: "Quantity",
          description:
            "When Equipment is selected, it automatically fetches the quantity. If you want to change, you can also make changes.",
        },
      },
      {
        element: "#quotation_create_ProductDetails_discount",
        popover: {
          title: "Discount",
          description:
            "When Equipment is selected, it automatically fetches the discount. If you want to change, you can also make changes.",
        },
      },
      {
        element: "#quotation_create_ProductDetails_amount",
        popover: {
          title: "Amount",
          description:
            "When Equipment is selected, it automatically fetches the amount. If you want to change, you can also make changes.",
        },
      },
      {
        element: "#quotation_create_ProductDetails_proddelete",
        popover: {
          title: "Delete Row",
          description: "Click this button to delete the current row.",
        },
      },
      {
        element: "#quotation_create_ProductDetails_conveyanceCharges",
        popover: {
          title: "Enter / update Conveyance Charges",
          description: "Enter / update Conveyance Charges.",
        },
      },
      {
        element: "#quotation_create_ProductDetails_courierCharges",
        popover: {
          title: "Enter / update Courier Charges",
          description: "Enter / update Courier Charges.",
        },
      },
      {
        element: "#quotation_create_ProductDetails_bottomdiscount",
        popover: {
          title: "Enter Discount",
          description: "Enter your discount.",
        },
      },
      {
        element: "#quotation_create_ProductDetails_totalAmount",
        popover: {
          title: "Total Amount",
          description: "Total Amount before Tax.",
        },
      },
      {
        element: "#quotation_create_ProductDetails_SGST",
        popover: {
          title: "SGST",
          description:
            "Calculated SGST where you selected (This shows when State GST is selected).",
        },
      },
      {
        element: "#quotation_create_ProductDetails_CGST",
        popover: {
          title: "CGST",
          description:
            "Calculated CGST where you selected (This shows when State GST is selected).",
        },
      },
      {
        element: "#quotation_create_ProductDetails_GST",
        popover: {
          title: "GST",
          description:
            "Calculated GST where you selected (This shows when General GST is selected).",
        },
      },
      {
        element: "#quotation_create_ProductDetails_grandTotal",
        popover: {
          title: "Calculated Grand Total Amount",
          description: "Calculated Amount with Tax.",
        },
      },
      {
        element: "#invoice_create_save",
        popover: {
          title: "Save Invoice",
          description: "Click this button to save the Invoice.",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can create a Invoice.",
        },
      },
    ],
  });
};
