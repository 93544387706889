import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Checkbox,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import html2canvas from "html2canvas";
import { toPng } from "html-to-image";
import html2pdf from "html2pdf.js";
import moment from "moment";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { BASE_URL } from "../../global";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import { toast } from "react-toastify";
import ErrorModal from "../../utils/components/errorModal";
import Tables from "../../utils/components/tables";
import UnitechHeader from "../image/certificate_header.png";
import UnitechFooter from "../image/certificate_footer.png";
import DownloadPdfLoaderModal from "../../utils/components/downloadPdfLoader";
import ExcelDownload from "../../utils/components/excelDownload";
import { useSearchParams } from "react-router-dom";

var columns = [
  {
    id: "id",
    label: "Sr. No.",
    align: "left",
    minWidth: 40,
  },
  {
    id: "standardName",
    label: "Test Equipment name",
    align: "left",
    minWidth: 40,
  },
  {
    id: "stId",
    label: "Test Equipment Id",
    align: "left",
    minWidth: 40,
  },
  {
    id: "companyName",
    label: "Client Name",
    align: "left",
    minWidth: 40,
  },

  {
    id: "address",
    label: "Client Address",
    align: "left",
    minWidth: 40,
  },
  {
    id: "expectedReturnDate",
    label: "Expected Return Date",
    align: "left",
    minWidth: 40,
  },
  {
    id: "returnDate",
    label: "Return Date",
    align: "left",
    minWidth: 40,
  },
  {
    id: "engineerName",
    label: "Engineers Name",
    align: "left",
    minWidth: 40,
  },
];

let notNeededColumn = ["id", "standardId", "clientId", "lastModified","currentLocation","userId","status"];

const InOutReportList = () => {
  const [searchParams] = useSearchParams();
  const idsString = searchParams.get('ids');
  const  ids = JSON.parse(idsString) || [];
  const printComponentRef = React.useRef();
  const [selectStandards, setSelectStandards] = useState(ids.join(","));
  const [standardArr, setStandardArr] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [searchBy, setSearchBy] = React.useState("DUCID");
  const [searchKey, setSearchKey] = React.useState("");
  const [totalRows, setTotalRows] = React.useState(0);
  const [errormodalIsOpen, setErrorModalIsOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [finalData, setFinalData] = React.useState([]);
  const [downloadPdfloading, setDownlaodPdfLoading] = React.useState(false);
  const [initialRender, setInitialRender] = React.useState(true);
  let newIds = selectStandards?.split(",").map(Number);

  const openErrorModal = (errorMessage, stackTrace, fileName) => {
    setErrorMessage({ errorMessage, stackTrace, fileName });
    setErrorModalIsOpen(true);
  };
  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  const getStandardList = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + "standards?_where=(status,eq,1)")
      .then((res) => {
        const standardList = [];
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].standardName != null)
            standardList.push({
              id: res.data[i].id,
              label: res.data[i].standardName,
              stId: res.data[i].stId,
            });
        }
        setStandardArr(standardList);
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong!");
          }
      });
  };

  const getQuery = (count) => {
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey != "") {
      let colName = {
        "	Client Name": "companyName",
        "UUT Name": "instrumentName",
        "Location Of Instrument": "locationOfInstrument",
        Department: "department",
        DUCID: "DUCID",
        "Serial Number": "serialNumber",
        Model: "model",
      }[searchBy];
      whereConditions = `where ${colName} like '%${searchKey}%'`;
    }

    // if (_userType == 3)
    //   whereConditions +=
    //     whereConditions.length > 0
    //       ? `and cert.clientId = ${_clientId}`
    //       : `and cert.clientId = ${_clientId}`;
    // whereConditions +=
    //   whereConditions.length > 0
    //     ? " AND cert.status = 1"
    //     : "AND cert.status = 1";

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

    let data = {
      query: `SELECT s.*, c.companyName, a.address, st.standardName, st.stId, (SELECT GROUP_CONCAT(u.userName SEPARATOR ', ') FROM users u WHERE FIND_IN_SET(u.id, s.userId) > 0) AS engineerName  FROM standardInOut s LEFT JOIN clients c ON s.clientId = c.id LEFT JOIN addresses a ON SUBSTRING_INDEX(s.address, ',', 1) = a.id LEFT JOIN standards st ON s.standardId = st.id WHERE s.standardId IN (${selectStandards}) ${whereConditions}  order by id DESC ${pagination_settings}`,
    };

    if (count)
      data.query = `SELECT COUNT(*) AS no_of_rows  FROM standardInOut s LEFT JOIN clients c ON s.clientId = c.id LEFT JOIN addresses a ON SUBSTRING_INDEX(s.address, ',', 1) = a.id LEFT JOIN standards st ON s.standardId = st.id WHERE s.standardId IN (${selectStandards}) ${whereConditions}`;
    return data;
  };

  // api calls
  function getTotalRows() {
    let data = getQuery(true);

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setTotalRows(res.data[0]?.no_of_rows);
      })
      .catch((err) => {
        console.log("datasheet data fetching error: ", err);
        if (err.message !== "request_aborted") {
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(err.message, "getTotalRows", "InOut Report")
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
        }
      });
  }

  function fetchFinalData() {
    setInitialRender(false)
    getTotalRows();
    let data = getQuery();

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setFinalData(res.data);
      })
      .catch((err) => {
        console.log("srfs data fetching error: ", err);
        if (err.message !== "request_aborted") {
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(err.message, "fetchFinalData", "InOut Report")
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
        }
      });
  }

  const generateLetterHeadHeader = async () => {
    let htmlString = `
    <div>
      <img src=${UnitechHeader} style="width:100%; height:170px;"/>
    </div>`;
    let div = document.createElement("div");
    div.innerHTML = htmlString;
    document.body.appendChild(div);
    let canvas = await html2canvas(div, { scale: 1.3 });
    const img = new Image();
    img.src = canvas.toDataURL("image/jpeg", 0.90);
    document.body.removeChild(div);
    return img;
  };

  const generateLetterHeadFooter = async () => {
    let htmlString = `
    <div>
      <img src=${UnitechFooter} style="width:100%; height:150px;"/>
    </div>`;
    let div = document.createElement("div");
    div.innerHTML = htmlString;
    document.body.appendChild(div);
    let canvas = await html2canvas(div, { scale: 1.3 });
    const img = new Image();
    img.src = canvas.toDataURL("image/jpeg", 0.90);
    document.body.removeChild(div);
    return img;
  };

  const generateDynamicFooter = async (pageNo, totalPages) => {
    const formattedCurrentPage = pageNo.toString().padStart(2, "0");
    const formattedTotalPages = totalPages.toString().padStart(2, "0");
    const pageRange = `${formattedCurrentPage}/${formattedTotalPages}`;
    let htmlString = `
    <div style="padding:30px;">
    <table style="width:100%;">
        <tr>
         <td colspan="2" style="padding:5px;font-family: Calibri, sans-serif;line-height:25px;text-align:center">
          <b style="font-size:20px;">LABCITY CALIBRATION SERVICES</b>
        </td>
        </tr>
        <tr>
        <td colspan="2" style="padding:5px;font-family: Calibri, sans-serif;line-height:25px;text-align:right">
            <b style="font-size:20px;">Page Number : </b>
            <span style="font-size:19px;">${pageRange}</span>
          </td>
        </tr>
    </table>
</div>`;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 1, scale: 1 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };

  const generatePDF = async () => {
    setDownlaodPdfLoading(true);
    try {
      const element = printComponentRef.current;
      let pdf = await html2pdf()
        .from(element)
        .set({
          margin: [30, 1, 20, 1],
          filename: `masterInOut.pdf`,
          pagebreak: { after: "section", mode: ["css", "legacy"] },
          html2canvas: {
            dpi: 192,
            scale: 4,
            letterRendering: true,
            useCORS: true,
          },
          jsPDF: {
            orientation: "portrait",
            unit: "pt",
            format: "a4",
            compress: true,
          },
        })
        .toPdf()
        .get("pdf");

      const totalPages = pdf.internal.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setFontSize(9);
        pdf.setTextColor(0, 0, 0);
        pdf.setFont("helvetica", "bold");

        const text = "MASTER IN OUT REPORT";
        const pageWidth = pdf.internal.pageSize.getWidth();
        const textWidth =
          (pdf.getStringUnitWidth(text) * 12) / pdf.internal.scaleFactor;
        const x = (pageWidth - textWidth) / 2;
        pdf.setFontSize(12);
        pdf.text(text, x, 20);

        // border add for contnet
        //  const borderWidth = 0.6;
        //  const leftMargin = 15;
        //  const borderX = leftMargin;
        //  const borderY = 5;
        //  const rightMargin = 595 - leftMargin * 0.95;
        //  pdf.setLineWidth(borderWidth);
        //  pdf.rect(borderX, borderY, rightMargin - leftMargin, 750);

        // let image = await generateDynamicFooter(
        //   i,
        //   totalPages
        // );
        // pdf.addImage(image, 0, 760, 595, 85);

        // if (letterHead) {
        //   image = await generateLetterHeadFooter();
        //   pdf.addImage(image, 0, 772, 595, 70);
        // }
      }

      const blob = pdf.output("blob");
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `InOutReport.pdf`;
      a.click();

      setDownlaodPdfLoading(false);
    } catch (error) {
      console.error("Error generating PDF:", error);
      setDownlaodPdfLoading(false);
    }
  };

  const actions = [];

  useEffect(() => {
    getStandardList();
  }, []);

  useEffect(() => {
    if(!initialRender){
      fetchFinalData();
  }
  }, [page, rowsPerPage]);


  useEffect(() => {
    if(ids?.length > 0) fetchFinalData();
  }, [ids]);
 

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Autocomplete
            multiple
            id="outlined-basic"
            size="small"
            options={standardArr}
            value={standardArr?.filter((ele)=>newIds?.includes(ele.id)) || null}
            disableCloseOnSelect
            getOptionLabel={(option) => option.label || ""}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.stId}: {option.label}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Test Equipment"
                placeholder="select multiple Test Equipment"
              />
            )}
            onChange={(event, value) => {
              const selectedIds = value.map((option) => option.id).join(",");
              setSelectStandards(selectedIds);
            }}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2}>
          <Button
            variant="contained"
            size="small"
            sx={{ height:"40px" }}
            onClick={() => {
              fetchFinalData()
            }}
          >
            Prepare Data
          </Button>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2}>
          <Button
            variant="contained"
            size="small"
            sx={{ height:"40px" }}
            onClick={() => {
              generatePDF();
            }}
          >
            Download PDF
          </Button>
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2}>
          <ExcelDownload
            finalData={finalData}
            notNeededColumn={notNeededColumn}
          />
        </Grid>
      </Grid>
      <div style={{ marginTop: "50px" }}>
        <Tables
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          // handleDelete={handleDelete}
          columns={columns}
          finalData={finalData}
          actions={actions}
          totalRows={totalRows}
          printComponentRef={printComponentRef}
          isPrint={true}
        />
        <div style={{ width: "100%" }} id="header-section"></div>
      </div>

      {errormodalIsOpen && (
        <ErrorModal
          errormodalIsOpen={errormodalIsOpen}
          closeErrorModal={closeErrorModal}
          errorMessage={errorMessage}
        />
      )}
      {downloadPdfloading && (
        <DownloadPdfLoaderModal
          loading={downloadPdfloading}
          name={"Master IN OUT"}
        />
      )}
    </div>
  );
};

export default InOutReportList;
