import React, { useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../../../global";
import Tooltip from "@mui/material/Tooltip";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");



const columns = [
  { id: "id", label: "Sr. No" },
  { id: "instrumentName", label: "UUT Name", minWidth: 40 },
  { id: "ranges", label: "Range Name", minWidth: 30 },
  { id: "calibrationPointOne", label: "Calibration Point One", minWidth: 30 },
  { id: "calibrationPointTwo", label: "Calibration Point Two", minWidth: 30 },
  {
    id: "calibrationPointThree",
    label: "Calibration Point Three",
    minWidth: 30,
  },
  { id: "calibrationPointFour", label: "Calibration Point Four", minWidth: 30 },
  { id: "calibrationPointFive", label: "Calibration Point Five", minWidth: 30 },
  { id: "calibrationPointSix", label: "Calibration Point Six", minWidth: 30 },
  {
    id: "calibrationPointSeven",
    label: "Calibration Point Seven",
    minWidth: 30,
  },
  {
    id: "calibrationPointEight",
    label: "Calibration Point Eight",
    minWidth: 30,
  },
];

function createData(
  id,
  instrumentName,
  ranges,
  calibrationPointOne,
  calibrationPointTwo,
  calibrationPointThree,
  calibrationPointFour,
  calibrationPointFive,
  calibrationPointSix,
  calibrationPointSeven,
  calibrationPointEight
) {
  return {
    id,
    instrumentName,
    ranges,
    calibrationPointOne,
    calibrationPointTwo,
    calibrationPointThree,
    calibrationPointFour,
    calibrationPointFive,
    calibrationPointSix,
    calibrationPointSeven,
    calibrationPointEight,
  };
}

export default function RangeList() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [ranges, setRangeList] = React.useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getRangeList = (event) => {
    let url = BASE_URL;
    axiosWithToken
      .get(
        url +
          "xjoin?_join=range.ranges,_j,ins.instruments&_on1=(range.instrumentId,eq,ins.id)&_fields=ins.instrumentName,range.ranges,range.calPoint1,range.calPoint2,range.calPoint3,range.calPoint4,range.calPoint5,range.calPoint6,range.calPoint7,range.calPoint8,range.id"
      )
      .then((res) => {
        console.log(res.data);
        setRangeList(res.data);
        initializeDataTable();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const initializeDataTable = () => {
    $("#datatable-keytable").DataTable({
      destroy: true,
      keys: true,
      dom:
        "<'row mb-2'<'col-sm-9' B><'col-sm-3' >>" +
        "<'row mb-2'<'col-sm-9' l><'col-sm-3' f>>" +
        "<'row'<'col-sm-12' tr>>" +
        "<'row'<'col-sm-7 mt-2 mr-5 pr-4'i><'ml-5' p>>",
      bPaginate: false,
      bLengthChange: true,
      bFilter: true,
      bInfo: false,
      bAutoWidth: true,
      language: {
        zeroRecords: " ",
      },

      buttons: ["copy", "csv", "excel", "print"],
    });
  };

  useEffect(() => {
    getRangeList();
  }, []);

  const submitSiteDelete = (id) => {
    var jsonData = {
      status: -1,
    };
    let url = BASE_URL;
    axiosWithToken
      .patch(url + `ranges/${id}`, jsonData)
      .then((res) => {
        toast("range was deleted!");
        setTimeout(refresh, 1000);
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong!");
          }
      });
  };

  var refresh = () => {
    window.location.reload(false);
    // <Link to="/master/master/standard">Home</Link>
  };
  var rows = [];

  for (let i = 0; i < ranges.length; i++) {
    rows.push(
      createData(
        ranges[i].range_id,
        ranges[i].ins_instrumentName,
        ranges[i].range_ranges,
        ranges[i].range_calPoint1,
        ranges[i].range_calPoint2,
        ranges[i].range_calPoint3,
        ranges[i].range_calPoint4,
        ranges[i].range_calPoint5,
        ranges[i].range_calPoint6,
        ranges[i].range_calPoint7,
        ranges[i].range_calPoint8
      )
    );
  }

  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <Toolbar>
        <Button
          variant="contained"
          size="small"
          component={Link}
          to="/master/range/createRange"
          onClick={() => {
            {
            }
          }}
        >
          <b>ADD NEW RANGE</b>
        </Button>
      </Toolbar>
      <hr />

      <Table
        stickyHeader
        aria-label="sticky table"
        size="small"
        id="datatable-keytable"
      >
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                <b>{column.label}</b>
              </TableCell>
            ))}
            <TableCell>
              <b>Actions</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map((column) => {
                    let value = row[column.id];
                    if (column.id == "id") {
                      value = index + 1;
                    }
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                  <TableCell>
                    <ButtonGroup size="small" aria-label="small button group">
                      <Tooltip title="Edit Range" placement="top-start">
                        <Button
                          style={{
                            borderColor: "#dc3545",
                          }}
                          component={Link}
                          to={`/master/editRange/${row.id}`}
                        >
                          <PreviewIcon />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Delete Product" placement="top-start">
                        <Button
                          onClick={(e) => {
                            if (
                              window.confirm("Really want to delete range?")
                            ) {
                              submitSiteDelete(row.id);
                            }
                          }}
                        >
                          <DeleteIcon style={{ color: "#dc3545" }} />
                        </Button>
                      </Tooltip>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <ToastContainer />
    </TableContainer>
  );
}
