import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from "@mui/material/Checkbox";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
} from "@mui/material";
import moment from "moment";
import { getUsers } from "../../utils/databaseHelper";
import { fetchAddresses } from "../invoice/productDetails";

import axiosWithToken from "../../utils/components/axiosTokenConfig";

export default function CreateStandardInOut() {
  const [companyName, setCompanyName] = React.useState(null);
  const [address, setAddress] = React.useState("");
  const [purpose, setPurpose] = React.useState(null);
  const [engineerIds, setEngineerIds] = React.useState([]);
  const [remark, setRemark] = React.useState("");
  const [outwardDate, setOutwardDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [expectedReturnDate, setExpectedReturnDate] = React.useState(
    moment(new Date()).add(1, "days").format("YYYY-MM-DD")
  );
  const [siteInwardDate, setSiteInwardDate] = React.useState(
    moment(new Date()).add(1, "days").format("YYYY-MM-DD")
  );
  const [standards, setStandard] = React.useState("");
  const [clientsObj, setClientsObj] = React.useState([]);
  const [clientsOption, setClientsOption] = React.useState([]);
  const [checkPoints, setCheckPoints] = React.useState("");
  const [standardArr, setStandardArr] = React.useState([]);
  const [engineers, setEngineers] = React.useState([]);
  const [allAddress, setAllAddress] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const purposeList = [
    {
      label: "Onsite",
    },
    {
      label: "self-calibration",
    },
  ];

  const getClientList = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + "clients?_where=(status,eq,1)")
      .then((res) => {
        const clients = [];
        const tClientsObj = {};
        for (let i = 0; i < res.data.length; i++) {
          clients.push({
            label: res.data[i].id + ", " + res.data[i].companyName,
          });
        }
        res.data.map((e) => (tClientsObj[e.id] = e));
        setClientsObj(tClientsObj);
        setClientsOption(res.data);
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong!");
          }
      });
  };

  const getStandardList = () => {
    let url = BASE_URL;
    let sqlQuery={
      "query":`SELECT standards.* FROM standards LEFT JOIN standardInOut ON FIND_IN_SET(standards.id, standardInOut.standards) WHERE standardInOut.standards IS NULL OR standardInOut.standards = ''`
    }
    axiosWithToken
      .get(url + "standards?_where=(status,eq,1)")
      // .post(url +`dynamic`,sqlQuery)
      .then((res) => {
        const standardList = [];
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].standardName != null)
            standardList.push({
              id: res.data[i].id,
              label: res.data[i].standardName,
              stId: res.data[i].stId,
            });
        }
        setStandardArr(standardList);
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong!");
          }
      });
  };

  const getEngineers = () => {
    getUsers(2, (res) => {
      return res.data.map((e) => {
        return { ...e, label: e.userName };
      });
    })
      .then((engineers) => {
        setEngineers(engineers);
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong!");
          }
      });
  };

  useEffect(() => {
    getClientList();
    getStandardList();
    getEngineers();
  }, []);

  const refresh = () => {
    window.location.reload(false);
  };

  const handleSubmit = (event) => {
    let row = {
      clientId: companyName,
      standards: standards,
      purpose: purpose,
      outwardDate: outwardDate
        ? moment(outwardDate).format("YYYY-MM-DD")
        : null,
      expectedReturnDate: expectedReturnDate
        ? moment(expectedReturnDate).format("YYYY-MM-DD")
        : null,
      siteInwardDate: siteInwardDate
        ? moment(siteInwardDate).format("YYYY-MM-DD")
        : null,
        userIds: engineerIds,
      remark: remark,
      checkPoint: checkPoints,
    };
    let url = BASE_URL;
    axiosWithToken
      .post(url + "standardInOut", row)
      .then((res) => {
        toast.success("standards In/Out created successfully !");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong!");
          }
      });
  };

  const debouncedFetchAddresses = async (addressIds, shippingAddressIds) => {
    setLoading(true);
    const newAddressArray = await fetchAddresses(addressIds, shippingAddressIds);
    setAllAddress(newAddressArray);
    setLoading(false);
  };

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Create Test Equipment In/Out
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={clientsOption}
            getOptionLabel={(option) => `${option.id}, ${option.companyName}`}
            renderInput={(params) => <TextField {...params} label="Client" />}
            onChange={(event, value) => {
              if (value != null) {
                const addressIds = value?.address;
                const shippingAddressIds = value?.shippingAddress;
                 debouncedFetchAddresses(addressIds, shippingAddressIds);
                setCompanyName(parseInt(value.id));
                setAddress(
                  clientsObj[parseInt(value.id)].address
                );
              } else {
                setCompanyName(value);
                setAddress("");
              }
            }}
          />
           {loading && <p>Loading...</p>} {/* Display a loading indicator */}
        </Grid>

        {/* <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Address"
            size="small"
            value={address}
            InputLabelProps={{ shrink: true }}
            fullWidth
            variant="outlined"
            disabled
          />
        </Grid> */}

<Grid item xs={3} id="quotation_create_address">
          {allAddress?.length === 1? (
            <TextField
            // value={(allAddress?.[0]?.address) || (quotationObject?.address) || ""}
            value={
              allAddress?.length === 1
                ? allAddress[0]?.address // If allAddress has only one element, use its address
                // : allAddress.find((e) => e.id == (quotationObject?.address).split(",")?.[0])?.address || ""
                :""
            }
              id="outlined-basic"
              label="Address *"
              fullWidth
              variant="outlined"
              size="small"
              disabled
            />
          ) : (
            <Autocomplete
              options={allAddress}
              size="small"
              getOptionLabel={(option) => option?.address}
              // onChange={(event, value) => {
              //   updateQuoObject("address", `${value?.id},${value?.shipping?.id}`);
              //   setSelectedAddress(value)
              // }}
              renderInput={(params) => (
                <TextField {...params} label="Address *" variant="outlined" />
              )}
            />
          )}
        </Grid>

        <Grid item xs={3}>
          <Autocomplete
            multiple
            fullWidth
            id="checkboxes-tags-demo"
            size="small"
            options={
              standardArr.length
                ? standardArr.map((item) => {
                    return {
                      id: item.id,
                      title: item.label,
                      stId: item.stId,
                    };
                  })
                : []
            }
            disableCloseOnSelect
            getOptionLabel={(option) => option.title}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.stId}:{option.title}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Standards"
                placeholder="select multiple Standards"
              />
            )}
            onChange={(event, value) => {
              if (value.length) {
                var arrToStr = Array.prototype.map
                  .call(value, function (item) {
                    return item.id;
                  })
                  .join(",");
                setStandard(arrToStr);
              } else {
                setStandard("");
              }
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={purposeList}
            renderInput={(params) => <TextField {...params} label="Purpose" />}
            onChange={(event, value) => {
              if (value != null) {
                setPurpose(value.label);
              } else {
                setPurpose("");
              }
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Outward date "
            size="small"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={outwardDate}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setOutwardDate(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Expected return date"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={expectedReturnDate}
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setExpectedReturnDate(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
          multiple
            size="small"
            id="combo-box-demo"
            options={engineers}
            // value={engineerName}
            disableCloseOnSelect
            renderInput={(params) => (
              <TextField {...params} label="Engineer Name" />
            )}
            onChange={(_, value) => {
              const engineerIds = value.map((engineer) => engineer.id).join(",");
              setEngineerIds(engineerIds);
            }}
            
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Site Inward Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={siteInwardDate}
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setSiteInwardDate(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Remarks"
            value={remark}
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setRemark(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Check Points"
            InputLabelProps={{ shrink: true }}
            fullWidth
            minRows={3}
            multiline
            variant="outlined"
            maxRows={5}
            onChange={(e) => {
              setCheckPoints(e.target.value);
            }}
          />
        </Grid>
      </Grid>

      <br />
      <Toolbar
        style={{
          padding: "0px",
          overflow: "auto",
          display: "flex",
          justifyContent: "flex-end",
          gap: "20px",
          marginTop: "3rem",
        }}
      >
        <Button
          variant="contained"
          style={{ backgroundColor: "grey" }}
          size="small"
          onClick={() => {
            if (window.confirm("Are you sure you want to cancel?")) {
              window.history.back();
            }
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            handleSubmit();
          }}
        >
          Save
        </Button>
      </Toolbar>
    </Paper>
  );
}
