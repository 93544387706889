import { driver } from "driver.js";

export const editSRFGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Lets get started...",
          description:
            "This tour will guide you through all sections and components in this Inward form.",
        },
      },
      {
        element: "#editsrf-companyName",
        popover: {
          title: "Select Client",
          description: "Select Client using dropdown list.",
        },
      },
      {
        element: "#editsrf-address",
        popover: {
          title: "Set adddress",
          description:
            "Address automatically fetch when you select Client and if company has multiple address then using dropdown select address.",
        },
      },
      {
        element: "#editsrf-receiptdate",
        popover: {
          title: "Select Receipt Date",
          description:
            "Date automatically fetch but if you want to change then you can change also",
        },
      },
      {
        element: "#editsrf-dcno",
        popover: {
          title: "Enter DC No / RGP No",
          description: "",
        },
      },
      {
        element: "#editsrf-dcdate",
        popover: {
          title: "Select DC Date / RGP Date",
          description:
            "Date automatically fetch but if you want to change then you can change also",
        },
      },
      {
        element: "#editsrf-entrydate",
        popover: {
          title: "Select Entry Date",
          description: "You have to select entry date",
        },
      },
      {
        element: "#editsrf-comtdelvdate",
        popover: {
          title: "Select Committed delivery date",
          description:
            "Date automatically fetch but if you want to change then you can change also",
        },
      },
      {
        element: "#editsrf-serno",
        popover: {
          title: "Service request number ",
          description:
            "Service request number automatically generate when you select Client",
        },
      },
      {
        element: "#editsrf-pono",
        popover: {
          title: "Enter PO Number ",
          description: "Enter PO Number",
        },
      },
      {
        element: "#editsrf-podate",
        popover: {
          title: "Select PO Date ",
          description:
            "Date automatically fetch but if you want to change then you can change also",
        },
      },
      {
        element: "#editsrf-billingname",
        popover: {
          title: "Select Billing Name",
          description: "Select Billing Name using dropdown list",
        },
      },
      {
        element: "#editsrf-ducInwardDetails",
        popover: {
          title: "UUT Inward Details",
          description:
            "In this table select instrument name,enter least count,accuracy etc",
        },
      },
      {
        element: "#editsrf-ducInwardDetails-addButton",
        popover: {
          title: "Add new record",
          description:
            "When clicking this button you can add new row in below table",
        },
      },
      {
        element: "#editsrf-ducInwardDetails-copybtn",
        popover: {
          title: "Copy row",
          description:
            "When clicking this button you can copy this row and it will appear below",
        },
      },
      {
        element: "#editsrf-ducInwardDetails-deltebtn",
        popover: {
          title: "Delete row",
          description: "When clicking this button you can delete this row",
        },
      },
      {
        element: "#editsrf-ducInwardDetails-instrumentId",
        popover: {
          title: "Enter instrumentId",
          description: "",
        },
      },
      {
        element: "#editsrf-ducInwardDetails-DUCID",
        popover: {
          title: "Enter UUT ID",
          description: "",
        },
      },
      {
        element: "#editsrf-ducInwardDetails-serialNumber",
        popover: {
          title: "Enter Serial Number",
          description: "",
        },
      },

      {
        element: "#editsrf-ducInwardDetails-ranges",
        popover: {
          title: "Enter UUT range",
          description:
            "Click this button then modal will open you can enter UUT range",
        },
      },
      {
        element: "#editsrf-ducInwardDetails-lc",
        popover: {
          title: "Enter Least Count",
          description:
            "Click this button then modal will open you can enter Least Count",
        },
      },
      {
        element: "#editsrf-ducInwardDetails-make",
        popover: {
          title: "Enter UUT Manufacturer",
          description: "",
        },
      },
      {
        element: "#editsrf-ducInwardDetails-model",
        popover: {
          title: "Enter Model",
          description: "",
        },
      },
      {
        element: "#editsrf-ducInwardDetails-ConOfDuc",
        popover: {
          title: "Enter Conditon Of UUT",
          description: "",
        },
      },
      {
        element: "#editsrf-ducInwardDetails-calFrequency",
        popover: {
          title: "Select calibration frequency",
          description: "Select calibration frequency using dropdown list",
        },
      },
      {
        element: "#editsrf-ducInwardDetails-accuracy",
        popover: {
          title: "Enter accuracy",
          description:
            "Click this button then modal will open you can enter accuracy",
        },
      },
      {
        element: "#editsrf-ducInwardDetails-calibrationType",
        popover: {
          title: "Select calibration type",
          description: "Select calibration type using dropdown list",
        },
      },
      {
        element: "#editsrf-ducInwardDetails-location",
        popover: {
          title: "Select location",
          description: "Select location using dropdown list",
        },
      },
      {
        element: "#editsrf-ducInwardDetails-locationOfInstrument",
        popover: {
          title: "Enter Location Of Instrument",
          description: "",
        },
      },
      {
        element: "#editsrf-ducInwardDetails-calPoint",
        popover: {
          title: "Select Calibration Point",
          description: "Select Calibration Point using dropdown list",
        },
      },
      {
        element: "#editsrf-ducInwardDetails-calMethod",
        popover: {
          title: "Select Calibration Method",
          description: "Select Calibration Method using dropdown list",
        },
      },
      {
        element: "#editsrf-ducInwardDetails-department",
        popover: {
          title: "Enter Department",
          description:
            "Click this button then modal will open you can enter Department",
        },
      },
      {
        element: "#editsrf-ducInwardDetails-operatingRange",
        popover: {
          title: "Enter Operating Range",
          description:
            "Click this button then modal will open you can enter Operating Range",
        },
      },
      {
        element: "#editsrf-ducInwardDetails-uploadexcel",
        popover: {
          title: "Upload your excel",
          description:
            "Using this button you can upload your excel data will fetch in UUT Inward table",
        },
      },
      {
        element: "#editsrf-saveupdate",
        popover: {
          title: "Save Srf",
          description:
            "When you filled in all respected data you can clicking on this button then Inward will Save/Update",
        },
      },
      {
        element: "#editsrf_navbar_datasheet",
        popover: {
          title: "Datasheet",
          description: "When Inward is created then go to datasheet",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can create/updated any Inward",
        },
      },
    ],
  });
};

export const srfListGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Lets get started...",
          description:
            "This tour will guide you through all sections and components in this Inward List.",
        },
      },
      {
        element: "#srflist-createsrfbtn",
        popover: {
          title: "Create new Inward",
          description: "When click this button you can create new Inward",
        },
      },
      {
        element: "#srflist-datefrom",
        popover: {
          title: "Select Date",
          description: "Select start date",
        },
      },
      {
        element: "#srflist-dateto",
        popover: {
          title: "Select Date",
          description: "Select end date",
        },
      },
      {
        element: "#srflist-searchby",
        popover: {
          title: "Search Field",
          description: "Using this dropdown you can search",
        },
      },
      {
        element: "#srflist-searchbytext",
        popover: {
          title: "Enter Your Value",
          description:
            "When select previous dropdown value then enter your value which you want search",
        },
      },
      {
        element: "#srflist-searchbtn",
        popover: {
          title: "Search ",
          description:
            "When you select previous dropdown or enter value then click on this button you get result",
        },
      },
      {
        element: "#srflist-table",
        popover: {
          title: "Inward Table",
          description:
            "Inward Details Table where you can edit Inward,Downlaod,Delete,Dispatch",
        },
      },
      {
        element: "#srflist-editsrf",
        popover: {
          title: "Edit Inward Button",
          description: "Click this button to edit the Inward",
        },
      },
      {
        element: "#srflist-srfmail",
        popover: {
          title: "Mail Button",
          description: "Click this button to open Mail modal",
        },
      },
      {
        element: "#srflist-srfdispatch",
        popover: {
          title: "Dispatch Button",
          description: "Click this button to dispatch the Inward",
        },
      },
      {
        element: "#srflist-srfdispatchstatus",
        popover: {
          title: "Dispatch Status Button",
          description: "View the dispatch status of the Inward",
        },
      },
      {
        element: "#srflist-printsrf",
        popover: {
          title: "Print Button",
          description: "Click this button to downlaod the Inward",
        },
      },
      {
        element: "#srflist-deletesrf",
        popover: {
          title: "Inward Delete",
          description: "When Clicking this button you can delete Inward",
        },
      },
      {
        element: "#srflist-pagination",
        popover: {
          title: "Table Pagination",
          description: "When using this you can see number of Inward list",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can create/updated any Inward",
        },
      },
    ],
  });
};

export const viewsrfGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Lets get started...",
          description:
            "This tour will guide you through all sections and components in this Inward.",
        },
      },
      {
        element: "#srf-viewsrf_letterhead",
        popover: {
          title: "Select letterhead",
          description: "Select letterhead with or without",
        },
      },
      {
        element: "#srf-viewsrf_download",
        popover: {
          title: "Download Inward",
          description: "Clicking this button allows you to download the srf.",
        },
      },
      {
        element: "#srf-viewsrf_print",
        popover: {
          title: "Inward Print",
          description: "",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can download Inward",
        },
      },
    ],
  });
};
